<template>
  <div>
    <div class="d-flex">
      <ul class="tab">
        <li class="tile tile--tab tab__item">
          <router-link :to="{ name: 'Withdraw' }" exact tag="p" class="tile__content text-uppercase"
            >Withdraw Requests</router-link
          >
        </li>
        <li class="tile tile--tab tab__item active">
          <router-link :to="{ name: 'WithdrawAdmins' }" tag="p" exact class="tile__content text-uppercase"
            >Crowdfunding Admins</router-link
          >
        </li>
      </ul>

      <router-link tag="button" class="btn btn--primary" :to="{ name: 'WithdrawAdminNew' }">Add New Admin</router-link>
    </div>

    <div v-if="crowdfundingAdmins" class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0 mt-16">
      <table class="table table--striped">
        <thead>
          <tr class="align-middle">
            <th>Full Name</th>
            <th>Email</th>
            <th>Wallet Address</th>
            <th>Created At</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="admin in crowdfundingAdmins.data"
            :key="admin.id"
            :to="{ name: 'WithdrawAdmin', params: { id: admin.id } }"
            tag="tr"
            class="link--clone align-bottom"
          >
            <td>
              <span v-if="activated(admin.status)" class="tag tag--success">Active</span>
              <span v-else class="tag tag--gray">Inactive</span>
              <p>{{ admin.fullName }}</p>
            </td>
            <td>{{ admin.email }}</td>
            <td>{{ admin.address }}</td>
            <td v-html="getMomentLTSFormatted(admin.createdAt)" />
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ crowdfundingAdmins.data.length | format_admin_count_text }} in total</p>
      </div>
    </div>

    <p v-else>No admin available</p>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import momentltsFormat from '@/mixins/momentlts-format'
import formatActivate from '@/mixins/format-activate'

export default {
  mixins: [momentltsFormat, formatActivate],

  computed: {
    ...mapState('requestTransfer', ['crowdfundingAdmins'])
  },

  methods: {
    ...mapActions('requestTransfer', ['getCrowdfundingAdmins']),

    ...mapMutations('requestTransfer', ['CLEAR_CROWDFUNDING_ADMINS'])
  },

  created() {
    this.CLEAR_CROWDFUNDING_ADMINS()
    this.getCrowdfundingAdmins()
  }
}
</script>
